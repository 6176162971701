import React from 'react';
import '../styles/herobanner.css';

function Herobanner ({ title, title2, body }) {
    return(
        <div className="hero">
            <div className="hero_container text-center">
                <h1 className="mt-10  animate-fade">
                    {title}
                </h1>
                <h1 className='animate-fade'>
                    {title2}
                </h1>
                <p className='animate-fade'>
                    {body}
                </p>
            </div>
        </div>
    )
}

export default Herobanner