
import React from 'react';
import PostContent from '../components/PostContent';
import content1  from '../images/about/Hiking-at-Sierra-Madre.jpeg'
import resume  from '../images/2024JaneNohResume-2.pdf'


function About () {
  const domainUrl = window.location.origin;

    return (
    <div className="about">
      <PostContent className="" image={content1} title="Hello!👋 my name is Jane" 
                body="
Ever since I was a kid, I loved to draw—sketching anything I could get my hands on. That passion led me to study studio art at CSULB, and after graduating, I started out as a graphic designer. Over time, I found myself diving deeper into UI/UX design, collaborating with web developers and getting more curious about what was happening “under the hood.” That curiosity pushed me to take a coding bootcamp at UCI while still working as a UI/UX designer, and honestly, it was a game-changer. Learning to code felt like unlocking a whole new level—I could finally bridge the gap between design and development. From there, I got hooked on building apps and eventually made the jump to software development, where I get to blend my eye for design with the power of coding.                "
                 h3="" p=""
                 button="Resume"
                 url={domainUrl + resume}
                 />
    </div>
    )
}

export default About