
import React from 'react';
import Herobanner from '../components/Herobanner'
import DesignBody from '../components/DesignBody';
// import Footer from '../components/Footer'

function DesignHome () {
    return (
    <div className="App mt-[150px] z-50">
      <Herobanner title="Designing interfaces," title2="engineering experiences" body="UI/UX Design&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Web Development&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Graphic Design"/>
      <DesignBody />
    </div>
    )
}

export default DesignHome