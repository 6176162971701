
// import React from 'react';
import image1 from '../images/fnl_prints/soupbag.png';
import image2 from '../images/fnl_prints/gummies1.png';
import image3 from '../images/fnl_prints/gummies2.png';
import image4 from '../images/fnl_prints/window_decal.png';


const PostFnlPrintDesign = () => {
    return (
        <div className="post_body design_posts">
        <div className="post_container max-w-[1200px] w-full m-auto">
            <img src={image1} alt="content" className="text-center m-auto"/>
            <img src={image2} alt="content" className="text-center m-auto"/>
            <img src={image3} alt="content" className="text-center m-auto"/>
            <img src={image4} alt="content" className="text-center m-auto"/>

            </div>
        </div>
    )
}

export default PostFnlPrintDesign