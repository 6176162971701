
import React from 'react';
import Body from '../components/Body'
import Herobanner from '../components/Herobanner'
// import Footer from '../components/Footer'

function Home () {
    return (
    <div className="App mt-[150px] z-50">
      <Herobanner title="Designing interfaces," title2="Engineering experiences ✨" body="UI/UX Design&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Web Development&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Graphic Design"/>
      <Body />
    </div>
    )
}

export default Home