
import React from 'react';
import image1 from '../images/diva/DIVA EX.jpg';
import image2 from '../images/diva/DIVA EX2.jpg';

const PostDiva = () => {
    return (
        <div className="post_body design_posts">
        <div className="post_container">
            <img src={image1} alt="content" className="text-center m-auto"/>
            <img src={image2} alt="content" className="text-center m-auto"/>
            </div>
        </div>
    )
}

export default PostDiva